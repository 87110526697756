import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: var(--spacing-3);
	max-width: var(--layout-max-width);
	margin: auto;
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.footer.copyright};
	border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	a {
		color: inherit;
		text-decoration: none;
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		line-height: 1.2;
	}
	.MuiTypography-root {
		color: inherit;
		font-size: inherit;
		line-height: 1.2;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 calc(var(--spacing-1) * 17);
		justify-content: flex-start;
		text-align: left;
		min-height: 10px;
		margin-bottom: var(--spacing-3);
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		border: 0;
	}
`

export { BottomAreaWrapper }
