import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	flex-wrap: wrap;
	padding: 0;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	max-width: var(--layout-max-width);
	margin: auto;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 calc(var(--spacing-1) * 17) var(--spacing-3);
	}
	& .BHFooterSocialLinks {
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			justify-content: flex-end;
			padding: 0;
			display: none;
		}
		& .BHSocialWrapper {
			& a {
				margin: 0;
				padding: 16px;
				color: inherit;
			}
		}
	}
	& .footer-storelocator-link {
		width: 50%;
		justify-content: center;
		padding: var(--spacing-3) 0;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-radius: 0;
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		&:hover,
		&:active {
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			border-bottom: none;
			padding: 0;
			min-height: revert;
			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
			.MuiButton-startIcon {
				margin-right: var(--spacing-1);
			}
			&:hover,
			&:active {
				border-bottom: none;
			}
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: var(--spacing-3) 0;
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		.MuiTypography-root {
			font-size: inherit;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
			flex-grow: 0;
			margin-right: 60px;
			width: auto;
			border-bottom: none;
			border-right: none;
			padding: 0;
			.MuiSvgIcon-root {
				font-size: 1.4rem;
			}
		}
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper }
